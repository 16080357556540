<template>
  <div class="home" v-cloak>
    <!-- <div class="top-us" @click="topContentUsData.topContentUs = true">
      <div class="img"></div>
      <span>{{ $t('MessageBoard') }}</span>
    </div> -->
    <!-- 悬浮的对话框 -->
    <!-- <el-dialog
      class="us-form"
      :title="$t('MessageBoard')"
      :visible.sync="topContentUsData.topContentUs"
      width="300px"
      :close-on-click-modal="false"
      :before-close="closeTopConten"
    >
      <div v-show="!topContentUsData.successFalg" class="alt-box">
        <img src="../assets/img/indexLogo.png" />
        <span>{{ $t('thankMsg') }}</span>
        <span>{{ $t('contactYou') }}</span>
      </div>
      <div v-show="topContentUsData.successFalg">
        <el-form
          :model="topContentUsData.data"
          :rules="rules"
          ref="topContentUsDataRules"
          class="demo-ruleForm"
        >
          <el-form-item prop="contactInfo">
            <el-input
              v-model="topContentUsData.data.contactInfo"
              :placeholder="$t('leaveMsg')"
              type="textarea"
              :rows="8"
              resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item prop="contactName">
            <el-input
              v-model="topContentUsData.data.contactName"
              :placeholder="$t('enterName')"
              prefix-icon="el-icon-user"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item prop="contactTel">
            <el-input
              v-model="topContentUsData.data.contactTel"
              :placeholder="$t('enterPhone')"
              onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
              prefix-icon="el-icon-phone-outline"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item prop="contactEmail">
            <el-input
              v-model="topContentUsData.data.contactEmail"
              :placeholder="$t('enterEmail')"
              prefix-icon="el-icon-message"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-show="topContentUsData.successFalg"
          type="primary"
          @click="addMsgFun"
          >{{ $t('confirm') }}</el-button
        >
      </span>
    </el-dialog> -->

    <el-container>
      <el-header>
        <div class="header">
          <div class="nav">
            <div class="logo">
              <img src="../assets/img/newLogo.png" />
            </div>
            <div class="nav-title">
              <ul>
                <li
                  :class="item.url == $route.path ? 'active' : ''"
                  v-for="(item, index) in navName"
                  :key="index"
                  @click="togNavFun(item)"
                >
                  <span>{{ $t(item.en) }}</span>
                  <ul
                    class="child"
                    v-if="item.children && item.children.length"
                  >
                    <li
                      v-for="(ele, ind) in item.children"
                      :key="ind"
                      @click.stop="goTwoNavFun(item.url, ele)"
                    >
                      {{ ele.name }}
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="lang">
                <span class="btn" @click="togLang('ch')">中文</span>
                <span>/</span>
                <span class="btn" @click="togLang('en')">EN</span>
              </div>
            </div>
          </div>
        </div>
      </el-header>

      <el-main>
        <div ref="main" class="main">
          <keep-alive :max="20">
            <router-view v-if="isRouterAlive"></router-view>
          </keep-alive>
        </div>
      </el-main>

      <el-footer>
        <div class="footer-box">
          <!-- 联系我们 -->
          <div class="aboutus-box">
            <div class="footer">
              <div class="about-left">
                <div class="title">
                  <span>{{ $t('contentUs') }}</span>
                  <span>{{ $t('contentUsCh') }}</span>
                </div>
                <div class="msg">
                  <ul>
                    <li v-for="(ele, num) in contacts" :key="num">
                      <span><i class="el-icon-phone-outline"></i></span>
                      <span>{{ ele }}</span>
                    </li>
                    <li>
                      <span><i class="el-icon-phone-outline"></i></span>
                      <span>{{ $t('Telephone') }}：{{ afterSalesTel }}</span>
                    </li>
                    <li>
                      <span><i class="el-icon-message"></i></span>
                      <span>{{ $t('Email') }}：{{ contactMail }}</span>
                    </li>
                    <li>
                      <span><i class="el-icon-map-location"></i></span>
                      <span class="address-box">
                        <span
                          v-for="(item, index) in contactsAddressList"
                          :key="index"
                        >
                          {{ item }}
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="about-right">
                <div class="title">
                  <i class="el-icon-s-custom"></i>
                  <span>{{ contactTitle }}</span>
                </div>
                <div class="img-box">
                  <img :src="contactImgs" />
                </div>
              </div>
            </div>
          </div>
          <div class="footer-nav">
            <ul>
              <li
                v-for="(item, index) in navName"
                :key="index"
                @click="togNavFun(item)"
              >
                <span>{{ $t(item.en) }}</span>
              </li>
            </ul>
          </div>
          <div class="footer-alt">
            <p>
              <span>广州国巡机器人科技有限公司 © 版权所有</span>
              <a href="https://beian.miit.gov.cn" target="_blank">
                <span>粤ICP备2021160531号-1</span>
              </a>
            </p>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'Home',
  provide() {
    return {
      reload: this.reload
    }
  },

  data() {
    let validateCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('must')))
      } else {
        return callback()
      }
    }
    return {
      isRouterAlive: true,
      navName: [
        {
          name: '网站首页',
          en: 'Home',
          url: '/',
          children: []
        },
        {
          name: '产品中心',
          en: 'productCenter',
          url: '/productCenter'
        },
        {
          name: '用户案例',
          en: 'IndustrySolutions',
          url: '/IndustrySolutions'
        },
        {
          name: '新闻资讯',
          en: 'newsCenter',
          url: '/newsCenter',
          children: []
        },
        {
          name: '服务中心',
          en: 'service',
          url: '/service',
          children: []
        },
        // {
        // name:'人才招聘',
        // en:'Recruit',
        // url:'/recruit'
        // },
        {
          name: '关于国巡',
          en: 'aboutUs',
          url: '/aboutUs',
          children: []
        },
        {
          name: '加入我们',
          en: 'joinUs',
          url: '/joinUs',
          children: []
        }
      ],
      // 留言板
      topContentUsData: {
        topContentUs: false,
        successFalg: true,
        data: {
          contactName: '',
          contactTel: '',
          contactEmail: '',
          contactInfo: ''
        }
      },
      rules: {
        contactInfo: [{ validator: validateCode, trigger: 'blur' }],
        contactName: [{ validator: validateCode, trigger: 'blur' }],
        contactTel: [{ validator: validateCode, trigger: 'blur' }],
        contactEmail: [{ validator: validateCode, trigger: 'blur' }]
      },
      // 联系我们的数据
      contactImgs: '',
      afterSalesTel: '',
      contactMail: '',
      contactTitle: '',
      contacts: [],
      contactsAddress: '',
      // 新增地址数组
      contactsAddressList: []
    }
  },
  created() {
    // window.addEventListener('beforeunload', this.beforeunloadFn())
    // this.getProData()
    // this.getSolData()
    // 获取首页的联系方式
    this.getHomeContentData()
    // 设置全局搜索关键字
    this.setKeywords()
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.beforeunloadFn())
  },
  methods: {
    // 设置关键字
    setKeywords() {
      let keywords =
        '智能巡检机器人,轨道巡检机器人,智慧巡检系统,轨道机器人,配电间机器人,国巡机器人,广州国巡机器人科技有限公司,国巡机器人科技有限公司'

      this.$axios.get('/officialweb/data/getKeyword').then((res) => {
        if (res.data) {
          keywords = res.data
        }
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute('content', keywords)
      })
    },
    // 刷新页面
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    // 刷新或者销毁
    beforeunloadFn() {
      // console.log("刷新或销毁");
      // this.$router.push('/')
    },
    // 切换语言
    togLang(type) {
      // 修改显示语言
      this.$i18n.locale = type
    },
    // 留言板添加内容
    addMsgFun() {
      let isTrue = false
      this.$refs['topContentUsDataRules'].validate((valid) => {
        if (!valid) {
          isTrue = true
        }
      })
      if (isTrue) return

      this.$axios
        .post('/officialweb/data/addMessageBoard', this.topContentUsData.data)
        .then((res) => {})
        .catch(() => {})
      this.topContentUsData.successFalg = false
    },
    // 关闭留言板
    closeTopConten() {
      this.topContentUsData.data = {
        contactName: '',
        contactTel: '',
        contactEmail: '',
        contactInfo: ''
      }
      this.$refs.topContentUsDataRules.resetFields()
      this.topContentUsData.topContentUs = false
      this.topContentUsData.successFalg = true
    },
    goTwoNavFun(url, item) {
      this.$router.push({
        path: url,
        query: { item: item }
      })
    },
    togNavFun(item) {
      this.$router.push({
        path: item.url
      })
    },
    // 获取产品中心数据
    getProData() {
      // 产品中心列表数据
      let proFormData = { id: '', pageNo: '1', pageSize: '100' }
      this.$axios
        .post('/officialweb/data/findProductInfoList', proFormData)
        .then((res) => {
          this.navName[1].children = res.list.map((item) => {
            let ele = {}
            ele.name = item.productName
            ele.id = item.id
            return ele
          })
          // console.log("this.navName[1].children==》",res.list);
        })
        .catch(() => {})
    },
    // 获取解决方案数据
    getSolData() {
      // 产品中心列表数据
      solFormData = { id: '', pageNo: '1', pageSize: '100' }
      this.$axios
        .post('/officialweb/data/findGwCaseInfoList', solFormData)
        .then((res) => {
          // console.log(res);
          this.navName[2].children = res.list.map((item) => {
            let ele = {}
            ele.name = item.caseTitle
            ele.id = item.id
            return ele
          })
          // console.log("this.navName[2].children==>",this.navName[2].children);
        })
        .catch(() => {})
    },
    // 获取首页的联系方式
    getHomeContentData() {
      this.$axios
        .post('/officialweb/data/getGwContactUsBy')
        .then((res) => {
          this.contactImgs = res.data.contactImgs
            .replace('[', '')
            .replace(']', '')
          this.contacts = res.data.contacts.split('/')
          this.afterSalesTel = res.data.afterSalesTel
          this.contactMail = res.data.contactMail
          this.contactsAddress = res.data.contactsAddress
          this.contactTitle = res.data.contactTitle
          this.contactsAddressList = res.data.contactsAddressList
          // console.log('地址',res.data.contactsAddressList);
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
// 设置浮动的联系我们
// .top-us {
//   width: 100px;
//   height: 100px;
//   background-color: rgba(0, 0, 0, 0.5);
//   border-radius: 10px;
//   position: fixed;
//   top: 85%;
//   right: 4px;
//   z-index: 1000;
//   color: #fff;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   > .img {
//     width: 60px;
//     height: 60px;
//     background-image: url(../assets/img/contentUs.png);
//     background-repeat: no-repeat;
//     background-size: contain;
//   }
// }
// 设置悬浮的对话框
// .us-form {
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   animation: none;
//   display: flex;
//   flex-direction: column;
//   .alt-box {
//     width: 100%;
//     height: 320px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     > img {
//       width: 130px;
//       height: 100px;
//       margin-bottom: 20px;
//     }
//   }
//   /deep/ .el-button--primary {
//     background-color: #004ea2;
//     padding: 10px 20px;
//     &:hover {
//       color: white;
//       background-color: #006cdf;
//     }
//   }
//   /deep/ .el-textarea {
//     width: 270px;
//   }
//   /deep/ .el-dialog__body {
//     padding-bottom: 10px;
//   }
//   /deep/ .el-input {
//     // margin-top: 10px;
//     width: 270px;
//   }
//   /deep/ .el-dialog {
//     border-radius: 20px;
//     overflow: hidden;
//     min-width: 310px;
//     min-height: 530px;
//   }
//   /deep/ .el-dialog__header {
//     border-radius: 20px 20px 0 0;
//     background-color: #004ea2;
//     background-blend-mode: multiply, multiply;
//     padding: 10px;
//     text-align: left;
//     .el-dialog__title {
//       color: white;
//       margin-left: 8px;
//       font-size: 16px;
//     }
//   }
//   /deep/ button:focus {
//     outline: none;
//   }
//   /deep/ .el-dialog__headerbtn {
//     top: 8px !important;
//     background: url(../assets/img/hx.png) left no-repeat;
//     background-size: cover;
//   }
//   /deep/ .el-dialog__headerbtn i {
//     content: '修改下面的font-size可以改变图片的大小';
//     font-size: 25px;
//     visibility: hidden;
//   }
// }

[v-cloak] {
  display: none !important;
}
.active {
  color: #fff !important;
  background-color: @blueColor !important;
  span:hover {
    color: @mainColor;
  }
}
// 导航条名字点击后的效果
.active::before {
  width: 100% !important;
}
// 控制整个页面最小为1400px
.home {
  width: 100%;
  min-width: @mainSize;
  display: flex;
  flex-direction: column;
  position: relative;
}

.el-main {
  position: relative;
  z-index: 100;
  padding: 0;
  flex: 1 !important;
  margin-top: 80px;
}

// 对头部搜索样式设置
.el-header {
  position: fixed;
  padding: 0;
  height: 80px !important;
  z-index: 999;
  width: 100%;
  background-color: #fff;
  // background-image: radial-gradient(circle, transparent 1px, #fff 1px);
  // background-size: 4px 4px;
  // backdrop-filter: saturate(50%) blur(4px);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);

  // filter: grayscale(100%);
  // -webkit-filter: grayscale(100%);
  // -moz-filter: grayscale(100%);
  // -ms-filter: grayscale(100%);

  .header {
    width: 1400px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    .nav {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        width: 300px;
        img {
          display: inline-block;
          width: 100%;
        }
      }
      .nav-title {
        display: flex;
        ul {
          width: 1000px;
          display: flex;
          justify-content: flex-end;
          position: relative;
          &::after {
            content: '';
            clear: both;
          }
        }
        li {
          position: relative;
          margin: 0 5px;
          padding: 0 20px;
          font-size: 18px;
          line-height: 40px;
          text-transform: uppercase;
          cursor: pointer;
          border-bottom: 2px solid transparent;
          box-sizing: border-box;
          background-color: rgba(255, 255, 255, 0.3);
          backdrop-filter: blur(2px);
          &:hover {
            color: @blueColor;
            ul.child {
              opacity: 1;
            }
          }
          ul.child {
            opacity: 0;
            position: absolute;
            top: 40px;
            left: 0;
            width: 100%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 5px;
            box-sizing: border-box;
            background-color: #eee;
            overflow: hidden;
            z-index: 1000;
            transition: all 0.5s;
            li {
              width: 100%;
              height: 40px;
              line-height: 40px;
              text-align: center;
              color: rgb(56, 56, 56);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 16px;
              background-color: #fff;
              border-bottom: 1px solid #eee;
              &:hover {
                color: #fff;
                background-color: @blueColor;
              }
            }
          }
        }
      }
      .lang {
        width: 100px;
        font-size: 16px;
        line-height: 40px;
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(2px);
        span {
          padding: 0px;
          margin: 0 2px;
          line-height: 30px;
          .btn {
            cursor: pointer;
          }
          &:hover {
            color: @blueColor;
          }
        }
      }
    }
  }
}
.el-footer {
  width: 100%;
  height: 460px !important;
  padding: 0;
  .footer-box {
    width: 100%;
    height: 100%;
    // filter: grayscale(100%);
    // -webkit-filter: grayscale(100%);
    // -moz-filter: grayscale(100%);
    // -ms-filter: grayscale(100%);
    .aboutus-box {
      width: 100%;
      min-width: @mainSize;
      height: 350px;
      background-image: url(../assets/img/7-Y.jpg);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding-bottom: 20px;
      box-sizing: border-box;

      .footer {
        width: @mainSize;
        height: 100%;
        margin: auto;
        display: flex;
        letter-spacing: 2px;
        .about-left {
          flex: 3;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-left: 40px;
          box-sizing: border-box;
          .title {
            margin-top: 30px;
            font-size: 26px;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            span {
              &:last-child {
                color: #a4a5a6;
                font-size: 18px;
              }
            }
          }
          ul {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 700;
            li {
              margin: 10px 0;
              display: flex;
              span {
                display: inline-block;
                margin-right: 10px;
                text-align: left;
                color: #4a4a4a;
                i {
                  font-size: 16px;
                  color: @blueColor;
                }
              }
            }
          }
          // 地址样式修改
          .address-box {
            display: flex;
            flex-direction: column;
          }
        }
        .about-right {
          flex: 1;
          font-size: 18px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          .title {
            margin-bottom: 10px;
            text-align: left;
            i {
              font-size: 24px;
              margin-right: 10px;
            }
          }
          .img-box {
            width: 180px;
            height: 180px;
            padding: 5px;
            background-color: #fff;
            img {
              width: 100%;
              // height: 100%;
            }
          }
        }
      }
    }
    .footer-nav {
      width: 100%;
      height: 70px;
      background-color: #333;
      ul {
        width: @mainSize;
        height: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        li {
          height: 100%;
          padding: 0 20px;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span {
            display: inline-block;
            color: #909090;
            border: 1px solid transparent;
            padding: 5px;
            &:hover {
              cursor: pointer;
              border: 1px solid #fff;
              color: #fff;
            }
          }
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
    .footer-alt {
      width: 100%;
      height: 40px;
      background-color: #2a2a2a;
      p {
        width: @mainSize;
        height: 100%;
        padding: 0;
        margin: auto;
        line-height: 40px;
        font-size: 12px;
        color: #666;
        text-align: right;
        span {
          margin-right: 6px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
